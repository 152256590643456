import React, { useState, useEffect } from 'react';
import { Col, Button, Modal, Carousel } from 'react-bootstrap';

export const ProjectCard = ({ title, description, imgUrl, images }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  let bool_val = true;
  if (imgUrl.substring(imgUrl.length - 3) === 'mp4') {
    bool_val = false;
  }

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const handleResize = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        {bool_val && <img src={imgUrl} alt='Project Pic' width='100%' height='100%' />}
        {!bool_val && <video preload='none' playsInline autoPlay muted loop src={imgUrl} width='100%' height={'100%'} />}
        <div className="proj-txtx">
          <h3>{title}</h3>
          {/* <span>{description}</span> */}
          <Button variant="primary" onClick={handleShow} className="mt-3">
            View Details
          </Button>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title><span style={{color: 'black'}}>{title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
          {!isMobileView && images && images.length > 0 && (
            <Carousel>
              {images.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={image}
                    alt={`slide ${index}`}
                    style={{maxHeight: '300px', maxWidth: '600px', objectFit: 'cover' }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* Add additional buttons or actions here */}
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default ProjectCard;
