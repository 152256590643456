import React  from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/fcb_small.mp4";
import projImg2 from "../assets/img/sw_small.mp4";
import projImg3 from "../assets/img/fida_small.mp4";
import projImg5 from "../assets/img/hra.webp";
import projImg6 from "../assets/img/sandbox.webp";

import projImg7 from "../assets/img/chopshop.webp";
import projImg8 from "../assets/img/POS.webp";
import projImg10 from "../assets/img/hra-site.webp"

import get_top from '../assets/modals/get_top.png';
import get_last from '../assets/modals/get_last.png';
import graph from '../assets/modals/graph.png';
import fcb_rank_card from '../assets/modals/fcb_rank_card.png';
import wr from '../assets/modals/wr.png';
import sw_bl from '../assets/modals/sw_bl.png'
import get_all from '../assets/modals/get_all.png';
import rank_card from '../assets/modals/rank_card.png';
import inrole from '../assets/modals/inrole.png';

import 'animate.css';
// eslint-disable-next-line 
import TrackVisibility from 'react-on-screen';

let fcb = [get_top, get_last, fcb_rank_card, wr]
let sw = [sw_bl]
let fc = [get_all, graph, rank_card, inrole]

export const Projects = () => {

  const projects = [
    {
      title: <a target="_blank" href="https://discord.com/api/oauth2/authorize?client_id=977343050651168788&permissions=439429229680&scope=applications.commands%20bot" rel="noreferrer" style={{color: 'white'}}>ForzaCompBot</a>,
      description: "All in one bot which pulls the current WR for a Forza Horizon rivals track and competitive approved tunes. Currently supported: FH4 & FH5 w/ DLCs",
      imgUrl: projImg1,
      images: fcb
    },
    {
      title: "StreetBot",
      description: "All in one bot to help manage the Street Wars server in addition to automatic scripting for the Blacklist section",
      imgUrl: projImg2,
      images: sw
    },
    {
      title: "FIDA Core",
      description: "All in one Forza Competitive bot which oversees FIDA communites. This bot holds community wide ELO, progressions, medals, and more",
      imgUrl: projImg3,
      images: fc
    },
    {
      title: "HRA Submission Bot",
      description: "Discord bot for Horizon Racing Academy which uses OCR to read race submissions and grant members roles depending on their times",
      imgUrl: projImg5,
    },
    {
      title: "Sandbox Bot",
      description: "All in one discord bot for personal use which displays daily weather, sends Canvas announcements and assignment notifications, along with file conversion and more",
      imgUrl: projImg6,
    },
  ];

  const app_projects = [
    {
      title: "ChopShop",
      description: "A frequent shoppers best friend. A companion app which shows you the fastest way throughout a store based on a shopping list",
      imgUrl: projImg7,
    },
    {
      title: "Pizza Shop POS",
      description: "A full stack Java Desktop App which is a fully flegded Point-Of-Sale application for cashiers",
      imgUrl: projImg8,
    }
  ];

  const web_projects = [
    {
      title: <a target="_blank" href="https://horizonracingacademy.com/" style={{color: 'white'}} rel="noreferrer">Horizon Racing Academy</a>,
      description: "A WordPress framework for the Horizon Racing Academy. This site is for a racing learning academy to learn racecraft. Built in collaboration with Cast Haste",
      imgUrl: projImg10,
    },
  ];


  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            {/* <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}> */}
                <h2>Projects</h2>
                <p>Here you will find all of my projects. Most of them are primarily built with Python in the backend, while the websites are either React or HTML based.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Discord</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Apps</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Websites</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <p>Notable functions and integrations are Google Sheets API, Pandas, Daily Looping Scripts, ELO Management, Rankings, User Role Lookup, Inter-Server commmunication, Database Cross Checking and more.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          app_projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <p>These projects were my first impression of coding in a high pressure environment and delivering a large deliverable. These projects have very complex backend, where they have extreme potential if I were to pick them up again.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          web_projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p> */}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              {/* </div> */}
              {/* }
            </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
