import React from 'react';
import SkillItem from './SkillItem';
import skills from './SkillsFile';

export const Skills = () => {

    const sortedSkills = skills.slice().sort((a, b) => a.title.localeCompare(b.title));

    return (
        <section className="skill" id="skills">
            <div className="container">
                <div className="row">
                    <div className="col-12" style={{padding: '3%'}}>
                        <div className="text-center">
                            <h2>Skills</h2>
                            <p>
                                Over the years I have accumulated skills varying in several sectors
                                within the software engineering field. Feel free to scroll through
                                all of my skills.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    {sortedSkills.map(skill => (
                        <SkillItem key={skill.id} title={skill.title} svg={skill.svg}/>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Skills;
