import React from 'react';
import '../skills.css'

const SkillItem = ({ svg, title }) => {
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
            <div className="grid-item">
                {svg}
                <h3 className='text-center'>{title}</h3>
            </div>
        </div>
    );
};

export default SkillItem;
